#Contact {
  background-color: #130a00;
  width: 100%;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  position: relative;
}

#Contact .clayton {
  align-self: center;
  padding-bottom: 20px;
}

#Contact .info {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: 20px;
}

#Contact .info div {
  color: #f1e9e6;
  font-family: "acre", sans-serif;
  font-size: 22px;
  padding-top: 10px;
}

#Contact a {
  color: #f1e9e6;
  padding-top: 15px;
}

#Contact .bottom-button {
  font-family: "acre", sans-serif;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0);
  border: 4px #e2f877 solid;
  color: #e2f877;
  padding: 15px 0px;
  font-size: 20px;
  width: 80%;
  margin: auto;
  max-width: 300px;
  display: inline-block;
  transition-duration: 150ms;
}

#Contact .bottom-button:hover,
#Contact .bottom-button:focus {
  transform: scale(1.1);
}

@media only screen and (min-width: 500px) {
  #Contact {
    flex-flow: row;
    justify-content: flex-end;
    height: 250px;
  }
  #Contact .clayton {
    position: relative;
    max-width: 35%;
    top: -30px;
    align-self: flex-start;
  }

  #Contact .info {
    padding: 30px;
    padding-left: 50px;
    align-items: flex-end;
    justify-content: flex-start;
  }

  #Contact .info div {
    font-size: 18px;
  }

  #Contact button {
    font-size: 22px;
    width: 100%;
    margin: 0;
  }
}
