#About {
  background-color: #ffde7a;
  padding-bottom: 120px;
  position: relative;
}

#About a {
  color: #887027;
  font-weight: bold;
}

#About .folder-box {
  padding-bottom: 100px;
}

#About .holder {
  display: flex;
  flex-flow: column;
}

#About .picture-holder {
  position: relative;
  width: 100%;
}

#About .clayton {
  width: 110%;
  position: absolute;
  top: -100px;
}

@media only screen and (min-width: 650px) {
  #About {
    padding-bottom: 120px;
  }

  #About .holder {
    flex-flow: row;
    max-width: 1000px;
    margin: auto;
    position: relative;
    height: fit-content;
  }

  #About p {
    font-size: 20px;
  }

  #About .folder-box {
    width: 100%;
    padding-right: 200px;
    padding-bottom: 20px;
    padding-left: 20px;
  }

  #About .picture-holder {
    /* width: fit-content; */
    align-self: flex-start;
    max-width: 20%;
  }

  #About .clayton {
    top: 30px;
    left: -200px;
    width: auto;
    /* height: 500px; */
    /* min-height: 100%; */
  }
}
