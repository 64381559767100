#Top {
  background-color: #130a00;
  color: #f1e9e6;
  height: 60vh;
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: 40vh;
}

.initials,
.big-name {
  display: none;
}

#TopNav {
  display: flex;
  width: 100%;
  flex-flow: column;
  align-items: center;
  padding: 10px;
  height: 40vh;
  justify-content: space-around;
  background-color: #130a00;
  position: fixed;
}

.wide-name {
  margin: 30px;
  align-self: center;
  width: 100%;
  max-width: 335px;
  display: inline-block;
  justify-self: center;
}

.contact-button {
  display: inline-block;
  width: 70%;
  max-width: 250px;
  border: #e2f877 4px solid;
  color: #e2f877;
}

#Top .picture-holder {
  position: relative;
  width: 100%;
  height: 60vh;
}

#Top .clayton {
  height: 130%;
  position: absolute;
  left: -25vh;
}

@media only screen and (min-width: 700px) {
  .initials,
  .big-name {
    display: inline-block;
  }

  .wide-name {
    display: none;
  }

  #Top {
    top: 80px;
    height: 100vh;
  }

  #TopNav {
    flex-flow: row;
    justify-content: space-between;
    height: 80px;
    padding: 0px 15px;
    z-index: 30;
  }

  .initials img {
    height: 100%;
  }

  .initials {
    height: 70%;
  }

  .contact-button {
    padding: 10px;
  }

  .wide-holder {
    display: flex;
    position: relative;
    width: 100%;
    height: 100vh;
  }

  #Top .picture-holder {
    height: 100vh;
    width: 50%;
  }

  #Top .clayton {
    width: 180%;
    max-width: 100vh;
    align-self: center;
    height: auto;
    /* top: 10%; */
    right: -20vw;
    left: normal;
  }

  #Top .name-holder {
    position: relative;
    height: 100vh;
    width: 50%;
  }

  .big-name {
    width: 100%;
    right: 5%;
    top: 20%;
    position: absolute;
    /* top: 30vh; */
    /* right: 5vh; */
    /* height: 50%; */
  }

  @media only screen and (min-height: 700px) {
    #Top .clayton {
      bottom: 0px;
    }
  }
}
