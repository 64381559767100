@font-face {
  font-family: "acre";
  src: url("./assets/jonathan_ball_-_acre-medium-webfont.woff2") format("woff2"),
    url("./assets/jonathan_ball_-_acre-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html * {
  box-sizing: border-box;
  color: #130a00;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto Slab", serif;
}

.App {
  width: 100%;
  overflow: hidden;
}

button:focus,
a:focus {
  outline-width: thick;
  outline-offset: 4px;
  outline: 4px solid rgba(0, 35, 90, 0.733);
}

button:focus,
button:hover {
  transform: scale(1.1);
}

button {
  font-family: "acre", sans-serif;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0);
  border: 4px #e2f877 solid;
  color: #e2f877;
  padding: 15px 0px;
  font-size: 34px;
  transition-duration: 500ms;
}

button:hover,
button:focus {
  cursor: pointer;
}

h1,
h2 {
  font-family: "acre", sans-serif;
  margin: 0;
}

h2 {
  font-size: 60px;
}
ul {
  margin: 2px 0px 10px;
  list-style: none;
  padding-left: 30px;
}

p,
ul,
div {
  font-size: 16px;
}

.spacer {
  position: relative;
  height: 100vh;
}

.folder {
  filter: drop-shadow(0px -3px 1px rgba(0, 0, 0, 0.25));
  padding: 10px;
  padding-top: 60px;
  position: relative;
  top: 0px;
  /* min-height: 100vh; */
}

.folder-box {
  padding: 10px;
}

@media only screen and (min-width: 700px) {
  .folder {
    padding-top: 40px;
  }
}
