#Skills {
  background-color: #ffb796;
  filter: drop-shadow(0px -3px 1px rgba(0, 0, 0, 0.25))
    drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.25));
  position: relative;
}

#Skills h1 {
  font-size: 80px;
  font-weight: normal;
}

#Skills h2 {
  font-size: 50px;
}

#Skills h3 {
  margin-bottom: 5px;
}

#Skills a {
  color: #935031;
  font-weight: bold;
}

#Skills ul {
  list-style: disc;
}

#Skills li::marker {
  color: #ffb796
}

#Skills .folder-box {
  background-color: #ffeee7;
  margin-bottom: 10px;
}

.reverse {
  display: none;
}

#Skills .header {
  display: flex;
  flex-flow: column-reverse;
  align-items: flex-end;
  padding-left: 30px;
  padding-right: 30px;
}

.bars {
  width: 70%;
  height: 80px;
}

#Skills .picture-holder {
  position: relative;
  width: 100%;
  height: 200px;
  z-index: 3;
}

#Skills .clayton {
  width: 130%;
  position: absolute;
  left: -30%;
  top: -100px;
  z-index: 2;
}

#Skills .brain-box {
  position: relative;
  z-index: 5;
}

@media only screen and (min-width: 500px) {
  #Skills .header {
    flex-flow: row;
    justify-content: space-around;
  }

  .reverse {
    display: inline-block;
    filter: FlipH;
    transform: scaleX(-1);
  }

  .bars {
    width: 20%;
  }

  .flex-display {
    display: flex;
  }
  #Skills .picture-holder {
    max-width: 40%;
  }

  #Skills .clayton {
    top: -50px;
  }
}

@media only screen and (min-width: 800px) {
  #Skills .picture-holder {
    max-width: 30%;
  }

  .brain-holder {
    display: flex;
    justify-content: space-between;
  }

  .brain-box {
    width: 47%;
  }
}
