.tab {
  font-family: "acre", sans-serif;
  position: relative;
  text-align: center;
  z-index: 30;
  height: 0px;
  top: -70px;
  filter: drop-shadow(0px -3px 1px rgba(0, 0, 0, 0.25));
}

.tab a {
  text-decoration: none;
  transition-duration: 150ms;
}

.tab a:hover,
.tab a:focus {
  font-size: 33px;
}

.tab-holder {
  top: 15px;
  max-width: 200px;
  height: 60px;
  width: 200px;
  position: relative;
}

.tab-back {
  transform: perspective(5px) rotateX(2deg);
  transform-origin: bottom;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: -12px;
  position: absolute;
  border-radius: 4px;
}

.tab-text {
  font-size: 30px;
  z-index: 5;
  position: relative;
  top: 10px;
  font-weight: bold;
}

.tab-bar {
  width: 100%;
  height: 30px;
}

@media only screen and (min-width: 700px) {
  .sticky-top,
  .sticky-bottom {
    position: fixed;
    width: 100%;
  }

  #About-tab .tab-holder {
    left: 100px;
  }

  #About-tab.sticky-top {
    top: 20px;
  }

  #About-tab.sticky-bottom {
    bottom: 90px;
    top: auto;
  }

  #Projects-tab .tab-holder {
    left: 280px;
  }

  #Projects-tab.sticky-top {
    top: 25px;
  }

  #Projects-tab.sticky-bottom {
    bottom: 75px;
    top: auto;
  }

  #Skills-tab .tab-holder {
    left: 460px;
  }

  #Skills-tab.sticky-top {
    top: 30px;
  }

  #Skills-tab.sticky-bottom {
    bottom: 70px;
    top: auto;
  }
}
